import { Box, Flex } from 'uikit'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  width: 100%;
`

export const StyledInputCurrencyWrapper = styled(Flex)`
  width: 100%;
`
