import styled from 'styled-components'
import { Text, Flex, CardBody, CardFooter, Button, AddIcon } from 'uikit'
import NextLink from 'next/link'
import { useTranslation } from 'contexts/Localization'
import { TranslationKey } from 'contexts/Localization/types'

export interface SwapTabItemProps {
  current?: number | null
  index?: number | null
  title?: TranslationKey | null
}

const TabItem = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: #666666;
  line-height: 25px;
  padding: 2px 0;

  &.act {
    color: #ffffff;
    border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  }
`

function SwapTabItem({ current, title, index }: SwapTabItemProps) {
  const { t } = useTranslation()
  return (
    <TabItem className={index === current ? 'act' : ''}>
      <NextLink href={'/' + title.toLowerCase()}>{t(title)}</NextLink>
    </TabItem>
  )
}

export default function SwapTab(props: SwapTabItemProps) {
  const list = ['Swap', 'Liquidity']
  return (
    <Flex mt="24px" mb="10px" width="100%" justifyContent="center">
      <Flex width="50%" maxWidth={"160px"} alignItems="flex-start" justifyContent="space-between">
        {list.map((item, index) => (
          <SwapTabItem key={index} index={index} title={item} {...props} />
        ))}
      </Flex>
    </Flex>
  )
}
