import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import {Button, Modal, Box, Flex, Text, useMatchBreakpoints, IconButton, CopyIcon, useModal} from 'uikit'
import { ModalActions, ModalInput } from 'components/Modal'
import { useTranslation } from 'contexts/Localization'
import { copyText } from 'utils/copyText'
import {Trade} from "../sdk";
import {getTokenSlippage} from "../config";
import SettingsModal from "./Menu/GlobalSettings/SettingsModal";

type TokenInfo = {
  name?: string
  symbol?: string
  decimals?: number
}

interface WithdrawModalProps {
  onConfirm?: () => void
  onDismiss?: () => void
    trade?: Trade
}

const ContainerBox = styled(Box)`
  background: #222428;
  border-radius: 15px;
  border: 1px solid #2d2f35;
  width: 100%;
`
const ItemCard = styled.div`
  padding: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .laber{
    min-width: 250px;
  }
`
const Laber=styled.div`
padding: 10px;
`

const SigSettingModal: React.FC<WithdrawModalProps> = ({ onDismiss,  trade }) => {
  const { t } = useTranslation()
    const [onPresentSettingsModal] = useModal(<SettingsModal />)

    const getRate = (data:Trade) =>{
      var sag = 0;
      for(let i=0;i<data.route.path.length;i++){
        sag = sag+getTokenSlippage(data.route.path[i]['address']);
      }
      return sag;
    }

    return (
    <Modal title={t('Warning')} onDismiss={onDismiss}>
        <Text fontSize="18px" color={'primary'}>
            {t('The sliding point tolerance is set too low, please set it to above %rate%',{rate:getRate(trade)+'%'})}
        </Text>
      <ModalActions>
        <Button variant="secondary" onClick={onPresentSettingsModal} width="100%">
          {t('Settings')}
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default SigSettingModal
